
import { computed, defineComponent, getCurrentInstance } from 'vue';
import {
  SfChevron
} from '@storefront-ui/vue'
import { formatRegionUrl } from '$modules/shipping/utils/region-url';
import { localizedRoute } from '@vue-storefront/core/lib/multistore'

export default defineComponent({
  name: 'MenuPromotion',
  components: {
    SfChevron
  },
  props: {
    promoBlock: {
      type: String,
      default: null
    }
  },
  setup (props) {
    const { proxy: vm } = getCurrentInstance()

    const extractPromoData = (htmlString: string) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, 'text/html');
      const link = doc.querySelector('a');
      return {
        href: link?.getAttribute('href') || '/promotion/vitayemo-na-varusua',
        text: link?.textContent || ''
      };
    }

    const promoData = computed(() => extractPromoData(props.promoBlock || ''))

    const path = computed(() => (
      localizedRoute(formatRegionUrl(promoData.value.href))
    ))

    const promoText = computed(() => promoData.value.text)

    const close = () => vm.$emit('close')

    return {
      close,
      path,
      promoText
    }
  }
});
