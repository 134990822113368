<template>
  <div v-show="isOpen" class="m-menu bg-white">
    <div class="m-menu__content">
      <MMobileMenuGrid @close="closeMenu" />
      <MenuPromotion
        v-if="promoBlock"
        :promo-block="promoBlock"
        @close="closeMenu"
      />
      <ul class="m-menu__submenu-nav">
        <li
          v-for="(subMenuItem, i) in subMenuFirstGroup"
          :key="i"
          class="o-header__submenu-nav-item"
        >
          <AMenuItem
            :link="subMenuItem.path"
            :item="subMenuItem"
            @click.native="closeMenu"
          />
        </li>
      </ul>
      <ul class="m-menu__submenu-nav">
        <li>
          <div
            data-transaction-name="Menu - Wishlist"
            class="m-menu__nav-item"
          >
            <span class="m-menu__nav-item-text">
              <AWishlistIcon class="m-menu__wishlist-icon" />
            </span>
          </div>
        </li>
        <li>
          <div
            data-transaction-name="Menu - My Account"
            class="m-menu__nav-item"
          >
            <span class="m-menu__nav-item-text">
              <MAccountButton />
            </span>
          </div>
        </li>
      </ul>
      <ul class="m-menu__menu-nav">
        <li
          v-for="(mainMenuItem, i) in mainMenu"
          :key="i"
        >
          <AMenuItem
            :show-chevron="!!mainMenuItem.items"
            :item="mainMenuItem"
            @click.native="mainMenuItem.items ? openSidebar(mainMenuItem.path) : closeMenu()"
          />
        </li>
      </ul>
      <ul class="m-menu__menu-nav menu-bottom">
        <li>
          <div
            data-transaction-name="Menu - Open Language Mobile"
            class="m-menu__nav-item"
          >
            <MLanguageSwitcher
              v-if="isOpen"
              class="m-menu__ls"
              :full-name="true"
              :show-all="true"
              :clickable="true"
              :is-expand="true"
            >
              <template #default="{isOpen, currentLabel}">
                <div class="m-menu__ls-inner" :class="{ 'm-menu__ls-inner--open': isOpen }">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M21.7209 12.752C21.8565 11.03 21.5303 9.30294 20.7759 7.749C19.5273 8.93539 18.0513 9.85658 16.4369 10.457C16.5667 12.0503 16.4948 13.6537 16.2229 15.229C18.1874 14.7342 20.0488 13.8956 21.7209 12.752ZM14.6339 15.55C14.9533 14.023 15.065 12.4599 14.9659 10.903C14.0139 11.13 13.0209 11.25 11.9999 11.25C10.9789 11.25 9.98594 11.13 9.03394 10.903C8.93719 12.4599 9.04884 14.0227 9.36594 15.55C11.1117 15.8176 12.8881 15.8176 14.6339 15.55ZM9.77194 17.119C11.2521 17.2941 12.7478 17.2941 14.2279 17.119C13.7192 18.7566 12.9682 20.3088 11.9999 21.724C11.0316 20.3088 10.2806 18.7566 9.77194 17.119ZM7.77694 15.23C7.50358 13.6542 7.43167 12.05 7.56294 10.456C5.94823 9.85572 4.47186 8.93453 3.22294 7.748C2.46876 9.30233 2.14289 11.0297 2.27894 12.752C3.95108 13.8956 5.81247 14.7352 7.77694 15.23ZM21.3559 14.752C20.851 16.4629 19.8871 18.0029 18.5688 19.2047C17.2505 20.4065 15.6282 21.2241 13.8779 21.569C14.7545 20.0953 15.4234 18.5076 15.8659 16.851C17.7954 16.4466 19.6485 15.7384 21.3559 14.753V14.752ZM2.64394 14.752C4.32594 15.723 6.17394 16.44 8.13394 16.851C8.57645 18.5076 9.24542 20.0953 10.1219 21.569C8.37183 21.2242 6.74959 20.4067 5.43129 19.2051C4.11298 18.0035 3.14899 16.4638 2.64394 14.753V14.752ZM13.8779 2.43C16.3557 2.91701 18.548 4.34583 19.9939 6.416C18.9266 7.49438 17.6525 8.34608 16.2479 8.92C15.8695 6.62782 15.0657 4.42664 13.8779 2.43ZM11.9999 2.276C13.4594 4.4081 14.4182 6.84236 14.8049 9.397C13.9079 9.627 12.9679 9.75 11.9999 9.75C11.0319 9.75 10.0919 9.628 9.19494 9.397C9.58163 6.84235 10.5405 4.40808 11.9999 2.276ZM10.1219 2.43C8.93417 4.42663 8.13035 6.62781 7.75194 8.92C6.3474 8.3461 5.07325 7.4944 4.00594 6.416C5.45198 4.34613 7.64433 2.91666 10.1219 2.43Z" fill="#FF6000"/>
                  </svg>
                  <span class="m-menu__nav-item-text">
                    {{ $t('Language') }} (<span>{{ currentLabel }}</span>)
                  </span>
                  <div class="m-menu__dropdown m-menu__dropdown--language">
                    <SfChevron />
                  </div>
                </div>
              </template>
            </MLanguageSwitcher>
          </div>
        </li>
      </ul>
    </div>
    <SfSidebar
      :visible="isSidebarOpen"
    >
      <template
        #bar
        v-if="currentSubmenu.length"
      >
        <SfBar
          :title="$t(currentSubmenu[0].name)"
          :back="true"
        >
          <template #back>
            <SfButton
              data-transaction-name="Menu - Close Sidebar"
              @click.native="closeSidebar"
              class="sf-button--close a-submenu-header__button"
            >
              <span class="a-submenu-header__button-back" />
            </SfButton>
          </template>
        </SfBar>
      </template>
      <ul
        v-if="currentSubmenu.length"
        class="m-menu__menu-nav menu-bottom"
      >
        <li
          v-for="(subMenuItem, index) in currentSubmenu[0].items"
          :key="index"
          class="o-header__submenu-nav-item"
        >
          <div class="o-header__submenu-nav-item">
            <AMenuItem
              :link="localizedRoute(subMenuItem.link)"
              :item="subMenuItem"
              @click.native="closeMenu"
            />
          </div>
        </li>
      </ul>
    </SfSidebar>
  </div>
</template>
<script>
import { SfSidebar, SfButton, SfBar, SfChevron } from '@storefront-ui/vue';
import MMobileMenuGrid from 'theme/components/molecules/menu/m-mobile-menu-grid';
import MenuPromotion from 'theme/components/molecules/menu/menu-promotion';
import { formatRegionUrl } from '$modules/shipping/utils/region-url';
import AWishlistIcon from 'theme/components/atoms/a-wishlist-icon';
import MAccountButton from 'theme/components/molecules/account/m-account-button';
import MLanguageSwitcher from 'theme/components/molecules/m-language-switcher.vue';
import { certificatesSvg, deliveryForBusinessSvg, helpIconSvg } from 'theme/config/menu-svg';
import AMenuItem from 'theme/components/atoms/a-menu-item.vue';
import { defineComponent, computed, ref, watch } from 'vue';
import { useStoreHelpers } from '../../../../composables/useStore';
import { localizedRoute } from '@vue-storefront/core/lib/multistore';
import { useScrollLock } from '../../../../composables/useScrollLock';

export default defineComponent({
  components: {
    AMenuItem,
    MLanguageSwitcher,
    MMobileMenuGrid,
    SfSidebar,
    SfBar,
    SfButton,
    MenuPromotion,
    SfChevron,
    AWishlistIcon,
    MAccountButton
  },
  props: {
    promoBlock: {
      type: String,
      default: null
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    isSidebarOpen: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const currentMenu = ref(null)
    const { useAction, useMutation } = useStoreHelpers()
    const isOpen = computed(() => props.isOpen)
    const { disableScrollLock, enableScrollLock } = useScrollLock()
    const hideBottomNavigation = useAction('ui', 'hideBottomNavigation')
    const closeMenuMutation = useMutation('ui', 'closeMenu')

    const subMenuFirstGroup = ref([
      {
        icon: helpIconSvg,
        name: 'Help center',
        path: localizedRoute('/help')
      },
      {
        icon: certificatesSvg,
        name: 'Gift certificates',
        path: localizedRoute('/giftcards')
      },
      {
        icon: deliveryForBusinessSvg,
        name: 'Delivery for business',
        path: localizedRoute('/optovi-zakupivli')
      }
    ])

    const mainMenu = ref([
      {
        name: 'For clients',
        path: localizedRoute('/buyers'),
        items: [
          { name: 'Stock', link: localizedRoute(formatRegionUrl('/promotion')) },
          { name: 'Everything new', link: localizedRoute('/novelty') },
          { name: 'Shops', link: localizedRoute('/stores') },
          { name: 'Loyalty program', link: localizedRoute('/loyalty') },
          { name: 'Own TM', link: localizedRoute('/own-tm') },
          { name: 'Gift certificates', link: localizedRoute('/giftcards') },
          { name: 'Interview', link: localizedRoute('/quiz') },
          { name: 'Delivery for business', link: localizedRoute('/optovi-zakupivli') },
          { name: 'Help', link: localizedRoute('/help') },
          { name: 'Favorable offers from banks', link: localizedRoute('/vigidni-propozitsiyi-vid-bankiv') }
        ]
      },
      {
        name: 'For partners',
        path: localizedRoute('/partners'),
        items: [
          {
            name: 'Suppliers',
            link: localizedRoute('/suppliers')
          },
          { name: 'Tenders', link: localizedRoute('/tenders') },
          { name: 'Rent', link: localizedRoute('/rent') },
          { name: 'For advertisers', link: localizedRoute('/advertisers') },
          { name: 'Anti-corruption', link: localizedRoute('/anti-corruption') },
          { name: 'Financial Statements', link: localizedRoute('/finansova-zvitnist') }
        ]
      },
      { name: 'Work at VARUS',
        path: localizedRoute('/work'),
        items: [
          {
            name: 'Delicious work',
            link: localizedRoute('/smachna_robota')
          },
          { name: 'Luscious life with VARUS', link: localizedRoute('/juicy-life-with-varus') },
          { name: 'Hot vacancies', link: localizedRoute('/hot-vacancies') },
          { name: 'Career with taste', link: localizedRoute('/career-with-taste') },
          { name: 'Experience of your future', link: localizedRoute('/experience-of-your-future') }
        ]
      }
    ])

    const closeSidebar = () => {
      emit('click', false)
    }

    const closeMenu = () => {
      closeMenuMutation()
      hideBottomNavigation(false)
      closeSidebar();
    }

    const openSidebar = (link) => {
      currentMenu.value = link
      emit('click', true)
    }

    const currentSubmenu = computed(() => {
      const submenu = mainMenu.value
      return currentMenu.value ? submenu.filter(menu => menu.path === currentMenu.value) : []
    })

    watch(isOpen, () => {
      if (isOpen.value) {
        return enableScrollLock()
      }

      disableScrollLock()
    })

    return {
      currentMenu,
      openSidebar,
      currentSubmenu,
      closeMenu,
      closeSidebar,
      subMenuFirstGroup,
      mainMenu
    }
  }
})
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import '~theme/css/fonts';

.m-menu {
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  z-index: var(--header-menu-index);
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px 10px;
  overflow-y: auto;
  border-top: 1px solid rgba(195, 198, 208, .3);

  ul {
    display: flex;
    flex-direction: column;
    gap: 10px;

    li {
      display: flex;
      align-items: center;

      > a, > div {
        overflow: hidden;
        min-height: 40px;
      }
    }
  }

  &__ls {
    width: 100%;

    ::v-deep {
      .s-lsm__label {
        width: 100%;
      }

      .m-menu__ls-inner .m-menu__dropdown {
        transition: transform .1s ease;
      }

      .m-menu__ls-inner--open .m-menu__dropdown {
        transform: rotate(180deg);
      }
    }

    &-inner {
      margin-bottom: 0!important;
      z-index: 1;
      min-height: 40px;
      position: relative;
      background: var(--white);
    }
  }

  .sf-sidebar__aside {
    .sf-bar {
      padding: 0;
    }
  }

  @include for-desktop {
    display: none;
  }

  .router-link-exact-active {
    --menu-item-font-weight: bold;
  }

  &__close {
    display: flex;
    margin-left: auto;
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: 100%;

    .m-menu__submenu-nav {
      .orange-color {
        color: var(--orange);
      }
    }
  }

  &__menu-nav {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--gray);
    list-style: none;
    padding: 0 0 10px;
    margin: 0 0 10px;

    &.menu-bottom {
      padding: 0;
      margin: 0;
      border-bottom: none;
    }
  }

  &__submenu-nav {
    display: flex;
    flex-direction: column;
    list-style: none;
    border-bottom: 1px solid var(--gray);
    padding: 0 0 10px;
    margin: 0 0 10px;
  }

  &__nav-item, .m-menu__ls-inner {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--font-size-14);
    color: var(--black);
    width: 100%;
    box-sizing: border-box;
    gap: 10px;

    &:after {
      transform: rotate(-45deg);
    }

    &:focus {
      color: var(--orange-hover);
    }
  }

  &__nav-item-text {
    display: flex;
    flex: 1 1 auto;

    span {
      text-transform: lowercase;
    }
  }

  .a-submenu-header__button-back {
    @include font-icon(var(--icon-arrow-bold-left));

    &:before {
      color: var(--black);
      font-size: var(--font-size-12);
    }
  }
}

::v-deep {
  .a-wishlist-icon__count {
    display: none;
  }

  .m-menu__dropdown {
    --chevron-size: 9px;
    transform: rotate(270deg);
    padding: 8px;
  }

  .m-menu__dropdown.m-menu__dropdown--language {
    transform: rotate(360deg);
  }

  .a-wishlist-icon__caption {
    font-size: var(--font-size-14);
  }

  .m-menu__wishlist-icon {
    .a-wishlist-icon__icon {
      color: var(--orange);
    }
  }

  .a-account-icon{
    font-size: var(--font-size-14);
    font-weight: normal;
    display: -ms-inline-flexbox;
    display: inline-flex;

    &:before {
      width: 24px;
      display: flex;
      justify-content: center;
      align-content: center;
      color: var(--orange)!important;
      margin-right: var(--spacer-xs);
    }
  }
}

::v-deep {
  .sf-overlay {
    display: none;
  }
  .sf-sidebar__aside{
    top: 60px;
    box-shadow: none;
  }
  .sf-sidebar__top {
    display: none;
  }
  .sf-bar {
    --bar-background: transparent;
    --bar-padding: 0;
    --bar-height: 4.375rem;
    --bar-font-size: var(--font-size-22);
    --bar-font-weight: var(--font-bold);
    --bar-font-family: var(--font-family-headers);
    margin: 0 var(--spacer-20);
    border-bottom: 1px solid var(--gray);
    justify-content: flex-start;

    &__title {
      text-transform: uppercase;
      padding-left: var(--spacer-15);
    }
  }
  .sf-chevron__bar:after {
    height: 2px;
  }

  .a-wishlist-icon {
    margin: 0 10px 0 0;
  }

  .a-wishlist-icon__caption {
    color: var(--black);
  }
}
</style>
