<template>
  <div :class="{hide: isHide}" class="o-bottom-navigation">
    <no-ssr>
      <SfBottomNavigation>
        <SfBottomNavigationItem
          :label="$t('Home')"
          :is-active="isActive('home')"
          class="sf-bottom-navigation-item__home"
          data-transaction-name="Bottom Nav - Go To Home"
          @click.native.prevent="goToHome"
        />
        <SfBottomNavigationItem
          :label="$t('Catalog')"
          :is-active="isActive('menu')"
          class="sf-bottom-navigation-item__catalog"
          data-transaction-name="Bottom Nav - Go To Catalog"
          @click.native.prevent="goToCatalog"
        />
        <SfBottomNavigationItem
          :label="$t('Search')"
          :is-active="isActive('search')"
          class="sf-bottom-navigation-item__search"
          data-transaction-name="Bottom Nav - Go To Search"
          @click.native.prevent="goToSearch"
        />
        <SfBottomNavigationItem
          :label="$t('Cabinet')"
          :is-active="isActive('profile')"
          class="sf-bottom-navigation-item__profile"
          data-transaction-name="Bottom Nav - Go To Account"
          @click.native.prevent="goToAccount"
        />
        <SfBottomNavigationItem
          :label="basketLabel"
          :is-active="isActive('add_to_cart')"
          :class="{
            'o-bottom-navigation__cart--loading': isLoading,
            'has-totals': areTotalsVisible,
          }"
          class="o-bottom-navigation__cart sf-bottom-navigation-item__cart"
          data-transaction-name="Bottom Nav - Go To Cart"
          @click.native.prevent="goToCart"
        >
          <template #icon>
            <SfButton class="sf-button--pure sf-bottom-navigation-item__icon">
              <SfIcon
                :aria-label="$t('Open microcart')"
                size="24px"
              >
                <template #badge>
                  <SfBadge
                    class="o-bottom-navigation__badge sf-icon__badge sf-badge--number"
                    :class="{
                      'o-bottom-navigation__badge--show': areTotalsVisible || isLoading
                    }"
                  >
                    {{ itemsQuantity }}

                    <ALoadingSpinner
                      v-show="isLoading"
                      :size="18"
                      :weight="3"
                      background="transparent"
                      color="#fff"
                      :is-absolute-position="true"
                    />
                  </SfBadge>
                </template>
              </SfIcon>
            </SfButton>
          </template>
        </SfBottomNavigationItem>
      </SfBottomNavigation>
    </no-ssr>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { ModalList } from 'theme/store/ui/modals'
import { SfBottomNavigation, SfButton, SfIcon, SfBadge } from '@storefront-ui/vue';
import ALoadingSpinner from 'theme/components/atoms/a-loading-spinner';
import NoSSR from 'vue-no-ssr';
import { price } from '@vue-storefront/core/filters';
import { formatRegionUrl } from '$modules/shipping/utils/region-url';

export default {
  name: 'OBottomNavigation',
  components: {
    SfBottomNavigation,
    SfButton,
    SfBadge,
    SfIcon,
    ALoadingSpinner,
    'no-ssr': NoSSR
  },
  data () {
    return {
      authModalName: 'm-modal-authentication',
      isMounted: false
    }
  },
  mounted () {
    this.isMounted = true
  },
  computed: {
    ...mapGetters({
      itemsQuantity: 'cart/getNonGiftItemsQuantityWithQTY',
      activeModals: 'ui/activeModals',
      isLoggedIn: 'user/isLoggedIn',
      getCartTotal: 'cart/getCartTotal'
    }),
    ...mapState({
      isMobileCatalog: state => state.ui.isMobileCatalog,
      isSearchPanelVisible: state => state.ui.searchpanel,
      isBasketPanelVisible: state => state.ui.microcart,
      hideBottomNavigation: state => state.ui.hideBottomNavigation,
      isMobileMenu: state => state.ui.isMobileMenu,
      isCartConnecting: state => state.cart.isConnecting,
      isCartSync: state => state.cart.isSyncing,
      isCartSyncTotal: state => state.cart.isSyncingTotal,
      isDeliveryLoader: state => state.ui.deliveryLoader,
      isShippingDetailSave: state => state.shipping.loading.shippingDetailsSave
    }),
    isHide () {
      return this.hideBottomNavigation || this.isMobileMenu
    },
    isLoading () {
      return !this.isMounted ||
        this.isCartSync ||
        this.isCartSyncTotal ||
        this.isShippingDetailSave ||
        this.isDeliveryLoader ||
        this.isCartConnecting
    },
    isAuthActive () {
      return this.activeModals.some(modal => modal.name === this.authModalName)
    },
    isActive () {
      return (icon) => {
        switch (icon) {
          case 'home': {
            const isHomepage = this.$route.name === this.localizedRoute({ name: 'home', path: '/' }).name
            return isHomepage && !this.isMobileCatalog && !this.isSearchPanelVisible && !this.isBasketPanelVisible && !this.isAuthActive
          }
          case 'menu': {
            return this.isMobileCatalog && !this.isBasketPanelVisible && !this.isSearchPanelVisible && !this.isAuthActive
          }
          case 'search': {
            return this.isSearchPanelVisible && !this.isAuthActive && !this.isBasketPanelVisible
          }
          case 'profile': {
            const isProfile = (this.$route.name === this.localizedRoute({ name: 'my-account', path: 'my-account' }).name) || this.isAuthActive
            return isProfile && !this.isMobileCatalog && !this.isSearchPanelVisible && !this.isBasketPanelVisible
          }
          case 'add_to_cart': {
            return this.areTotalsVisible ||
              (this.isBasketPanelVisible && !this.isMobileCatalog && !this.isSearchPanelVisible && !this.isAuthActive)
          }
          default: {
          // we don't need to show active icon for profile and cart, because bottom navigation is below
            return false
          }
        }
      }
    },
    basketLabel () {
      return this.areTotalsVisible ? price(this.getCartTotal) : this.$t('Basket')
    },
    areTotalsVisible () {
      return this.itemsQuantity && !isNaN(this.getCartTotal)
    }
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
      closeModal: 'ui/closeModal',
      toggleMicrocart: 'ui/toggleMicrocart',
      closeMicrocart: 'ui/closeMicrocart'
    }),
    goToHome () {
      this.closeModal({ name: ModalList.Auth });
      this.$store.commit('ui/setSearchpanel', false)
      this.$store.commit('ui/closeCatalog')
      this.closeMicrocart()

      this.$router.push(this.localizedRoute(formatRegionUrl('/')));
    },
    goToCatalog () {
      this.$store.commit('ui/setSearchpanel', false)
      this.closeModal({ name: ModalList.Auth })
      this.closeMicrocart()

      this.isMobileCatalog
        ? this.$store.commit('ui/closeCatalog')
        : this.$store.commit('ui/openCatalog')
    },
    goToSearch () {
      this.$store.commit('ui/closeCatalog')
      this.$store.commit('ui/closeMenu')
      this.closeMicrocart()
      this.$store.commit('ui/setSearchpanel', !this.isSearchPanelVisible)
      this.closeModal({ name: ModalList.Auth })
    },
    goToAccount () {
      this.$store.commit('ui/setSearchpanel', false)
      this.$store.commit('ui/closeCatalog')

      if (this.isLoggedIn) {
        this.$router.push(this.localizedRoute('/my-account'))
      } else if (this.isAuthActive) {
        this.closeModal({ name: ModalList.Auth })
      } else {
        this.openModal({ name: ModalList.Auth, payload: 'auth' })
      }

      this.closeMicrocart()
    },
    goToCart () {
      this.$store.commit('ui/setSearchpanel', false)
      this.$store.commit('ui/closeCatalog')
      this.closeModal({ name: ModalList.Auth })
      this.toggleMicrocart()
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~theme/css/px2rem";
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import '~theme/css/fonts';

.o-bottom-navigation {
  position: relative;

  &__cart {

    ::v-deep .sf-bottom-navigation-item__label, .sf-badge {
      transition: all 200ms ease-in-out;
    }

    ::v-deep .sf-bottom-navigation-item__label {
      height: 14px;
    }

    &--loading {
      .sf-badge {
        color: transparent;
      }
    }

    &--loading.has-totals {
      ::v-deep .sf-bottom-navigation-item__label {
        color: transparent;
        height: 0;
      }
    }
  }

  &__badge {
    opacity: 0;
    pointer-events: none;
    transition: opacity 200ms;
    user-select: none;

    &--show {
      opacity: 1;
    }
  }

  @include for-mobile() {
    &.hide {
      display: none;
    }
  }

  @include for-desktop() {
    display: none;
  }
}

::v-deep .sf-bottom-navigation {
  --bottom-navigation-box-shadow: none;
  --bottom-navigation-background: var(--light-gray);
  --bottom-navigation-padding: 0 0.813rem;
  --bottom-navigation-z-index: 6;
  height: var(--bottom-navigation-height);
  border-top: 1px solid rgba(195, 198, 208, 0.5);

  @supports (-webkit-touch-callout: none) {
    padding: var(--spacer-10) 0 var(--spacer-20) 0;
  }

  &-item {
    --icon-color: var(--black);
    --bottom-navigation-item-color: var(--black);
    cursor: pointer;
    padding: 0;
    width: 20%;
    position: relative;

    &:before {
      font-size: var(--font-size-24);
      color: var(--black);
    }

    &__home {
      @include font-icon(var(--icon-home));
    }

    &__catalog {
      @include font-icon(var(--icon-catalog));
    }

    &__search {
      @include font-icon(var(--icon-search));
    }

    &__profile {
      @include font-icon(var(--icon-user));
    }

    &__cart {
      @include font-icon(var(--icon-cart));

      &:before {
        position: absolute;
        top: 0;
      }
    }
  }

  &-item--active {
    &:before {
      color: var(--orange);
    }

    .sf-bottom-navigation-item__label {
      color: var(--orange);
    }

    circle, path {
      fill: var(--orange);
    }
  }

  &-item__label {
    font-family: var(--font-family-inter);
    font-size: var(--font-size-12);
    padding-top: 2px;
  }
}

::v-deep {
  .sf-badge {
    --badge-background: var(--orange);
    --icon-badge-right: calc(-1 * 0.813rem);
    --badge-min-width: 1.375rem;
    --badge-min-height: 1.375rem;
    --badge-font-size: 0.563rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
