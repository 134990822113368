<template>
  <footer
    v-if="!hideFooter"
    :style="style"
    class="sf-footer o-footer footer"
  >
    <div class="o-footer__container">
      <div class="o-footer-column o-footer-column--sm">
        <ALogo :is-responsive="false" />
        <div class="o-footer-column__group">
          <MPhoneContact/>
          <MSocialContact />
        </div>
      </div>
      <SfFooterColumn
        v-for="linkGroup in links"
        :key="linkGroup.name"
        :title="linkGroup.name"
      >
        <SfList>
          <SfListItem
            v-for="link in linkGroup.children"
            :key="link.name"
          >
            <SfLink :link="localizedRoute(link.link)" class="sf-menu-item__label">
              {{ link.name }}
            </SfLink>
          </SfListItem>
        </SfList>
      </SfFooterColumn>
      <div class="o-footer-column o-footer-column--lg">
        <MFooterSubscribe class="o-footer-column__item" />
        <MHomeApps
          class="o-footer-column__item"
          :description="$t('Download our mob. Appendix')"
        />
        <MFooterBots class="o-footer-column__item" />
      </div>
    </div>
    <MFooterCopy />
  </footer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DeviceType from 'theme/mixins/DeviceType';
import { SfList, SfLink } from '@storefront-ui/vue';
import ALogo from '../atoms/a-logo';
import MFooterSubscribe from '../molecules/m-footer-subscribe';
import MHomeApps from '../molecules/m-home-apps';
import MFooterBots from '../molecules/m-footer-bots';
import MPhoneContact from '../molecules/m-phone-contact';
import MFooterCopy from '../molecules/m-footer-copy';
import MSocialContact from '../molecules/m-social-contact';
import SfFooterColumn from '@storefront-ui/vue/src/components/organisms/SfFooter/_internal/SfFooterColumn';
import { formatRegionUrl } from '$modules/shipping/utils/region-url';

export default {
  name: 'OFooter',
  mixins: [DeviceType],
  components: {
    MSocialContact,
    MFooterCopy,
    MFooterBots,
    MHomeApps,
    MFooterSubscribe,
    ALogo,
    SfList,
    SfLink,
    MPhoneContact,
    SfFooterColumn
  },
  data () {
    return {
      column: 5.5,
      multiple: true,
      open: [],
      items: []
    }
  },
  computed: {
    ...mapGetters('user', ['isLoggedIn']),
    style () {
      return { '--_footer-column-width': `${100 / this.column}%` }
    },
    hideFooter () {
      return this.isMobile && this.$route.path.includes('/my-account')
    },
    links () {
      return {
        orders: {
          name: this.$t('Buyers'),
          children: [
            { name: this.$t('Stock'), link: formatRegionUrl('/promotion') },
            { name: this.$t('Promotional newspaper'), link: '/gazeta-varus-0' },
            { name: this.$t('Everything new'), link: '/novelty' },
            { name: this.$t('Shops'), link: '/stores' },
            { name: this.$t('Loyalty program'), link: '/loyalty' },
            { name: this.$t('Own TM'), link: '/own-tm' },
            { name: this.$t('Gift certificates'), link: '/giftcards' },
            { name: this.$t('Interview'), link: '/quiz' },
            { name: this.$t('Delivery for business'), link: '/optovi-zakupivli' },
            { name: this.$t('Help'), link: '/help' },
            { name: this.$t('Favorable offers from banks'), link: '/vigidni-propozitsiyi-vid-bankiv' }
          ]
        },
        help: {
          name: this.$t('About company'),
          children: [
            { name: this.$t('About us'), link: '/about-company' },
            { name: this.$t('Contact us'), link: '/contacts' },
            { name: this.$t('Blog'), link: '/blog' },
            { name: this.$t('Press-kit'), link: '/press-kit' }
          ]
        },
        about: {
          name: this.$t('For partners'),
          children: [
            {
              name: this.$t('Suppliers'),
              link: this.localizedRoute('/suppliers')
            },
            { name: this.$t('Tenders'), link: '/tenders' },
            { name: this.$t('Rent'), link: '/rent' },
            { name: this.$t('For advertisers'), link: '/advertisers' },
            { name: this.$t('Anti-corruption'), link: '/anti-corruption' },
            { name: this.$t('Financial Statements'), link: '/finansova-zvitnist' }
          ]
        }
      };
    }
  },
  watch: {
    isMobile: {
      handler (mobile) {
        this.$nextTick(() => {
          this.open = mobile ? [] : [...this.items]
        });
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('ui', {
      openModal: 'openModal'
    }),
    toggle (payload) {
      if (!this.isMobile) return
      if (!this.multiple) {
        this.open = [payload]
      } else if (this.open.includes(payload)) {
        this.open = this.open.filter((item) => item !== payload)
      } else {
        this.open.push(payload)
      }
      this.$emit('change', this.open)
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/px2rem";

.product-layout {
  .o-footer {
    @include for-mobile {
      padding-bottom: var(--bottom-navigation-height);
    }
  }
}

.o-footer {
  border-top: 1px solid var(--color-zircon);
  --footer-width: auto;
  --footer-padding: var(--spacer-lg) 0 0 0;

  @include for-mobile {
    border-width: 2px;
  }

  @media (min-width: $tablet-min) {
    border-width: 1px;
  }

  .sf-footer-column {
    max-width: px2rem(215);
    width: 100%;

    ::v-deep {
      button {
        padding: 0 var(--spacer-30) var(--spacer-25) 0;
        background-color: transparent;
        border: none;

        @include for-mobile {
          padding: var(--spacer-20) 0;
          line-height: 1.1875rem;
          position: relative;
          width: 100%;
          text-align: left;
          -webkit-tap-highlight-color: transparent;
        }
      }

      .sf-footer-column__chevron {
        @media (min-width: $tablet-min) {
          display: none;
        }
      }
    }

    @include for-mobile {
      max-width: 100%;

      ::v-deep  .sf-footer-column__chevron {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  @include for-mobile {
    padding-bottom: 60px;
  }

  @include for-desktop {
    margin-top: 0;
  }

  &__container {
    box-sizing: border-box;
    padding: var(--spacer-sm) var(--spacer-sm) var(--spacer-2xs) var(--spacer-sm);
    display: flex;
    margin: auto;
    flex-wrap: wrap;

    @media (min-width: $tablet-min) {
      padding: var(--spacer-sm) var(--spacer-sm) var(--spacer-xs) var(--spacer-sm);
    }

    @include for-desktop {
      max-width: var(--max-width);
      padding: var(--spacer-xl) var(--spacer-sm);
      flex-wrap: nowrap;
    }
  }

  &-column {
    &--sm {
      width: 100%;

      @media (min-width: $tablet-min) and (max-width: $tablet-max) {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      @include for-desktop {
        padding-right: var(--spacer-20);
        width: 20%;
      }
    }

    &--lg {
      border-top: 1px solid var(--line-color);
      padding-top: var(--spacer-base);
      width: 100%;

      @media (min-width: $tablet-min) and (max-width: $tablet-max) {
        border-top: none;
        padding-top: var(--spacer-base);
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
      }

      @include for-desktop {
        min-width: 268px;
        width: 33%;
        padding-top: 0;
        border-top: none;
      }
    }

    &__item {
      margin-bottom: var(--spacer-25);

      @media (min-width: $tablet-min) and (max-width: $tablet-max) {
        width: 41%;
        box-sizing: border-box;
        margin-bottom: 0;

        &.m-home-apps {
          display: flex;
          justify-content: flex-end;
          width: auto;
          flex: 1 0 auto;
          padding-right: 5rem;
        }

        &.m-footer-bots {
          margin-bottom: var(--font-xl);
          padding-top: var(--spacer-xs);
        }
      }

      @media (min-width: $desktop-min) {
        &.m-footer-subscribe {
          margin-bottom: 0;
        }
      }

      @media (max-width: $mobile-max) {
        width: 100%;

        &.m-footer-subscribe {
          margin-bottom: var(--spacer-2xs);
        }
      }
    }

    &__group {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-bottom: var(--spacer-2xs);

      @media (min-width: $tablet-min) and (max-width: $tablet-max) {
        width: auto;
        flex: 1 0 auto;
        padding-left: 19%;
      }

      @media (min-width: $desktop-min) {
        display: block;
        width: auto;
        padding-bottom: 0;
      }
    }
  }

  .sf-menu-item {
    display: inline-flex;
  }

  .sf-menu-item__label {
    font: var(--font-normal) var(--font-sm) var(--font-family-inter);
    color: var(--dark-gray);

    &:hover {

      @include for-desktop {
        color: var(--orange-hover);
      }
    }
  }

  @media (min-width: $tablet-min) and (max-width: $tablet-max) {

    .sf-footer-column {
      --_footer-column-width: 33.333%;
      padding-right: 2rem;
      border-top: none;
      width: calc(33.333% - 2rem);
    }

    .sf-footer-column__chevron {
      --footer-column-chevron-display: none;
    }
  }

  @media (min-width: $desktop-min) {
    .m-home-apps {
      margin-bottom: var(--spacer-35);
    }

    .company-phone {
      margin: var(--spacer-20) 0;
    }

    .m-social-contact {
      padding-top: 0.688rem;
    }
  }
}
</style>
