
import { computed, defineComponent, getCurrentInstance } from 'vue';
import { formatRegionUrl } from '$modules/shipping/utils/region-url';
import { localizedRoute } from '@vue-storefront/core/lib/multistore';

export default defineComponent({
  name: 'MMobileMenuGrid',
  setup () {
    const { proxy: vm } = getCurrentInstance()

    const menuGrid = computed(() => {
      return [
        {
          label: 'Prize draws and promotions',
          class: 'm-menu-grid__item--prize-draws-and-promotions',
          image: '/assets/images/menu/menu-grid/prize-draws.svg',
          link: localizedRoute(formatRegionUrl('/promotion'))
        },
        {
          label: 'Product ratings',
          class: 'm-menu-grid__item--product-ratings',
          image: '/assets/images/menu/menu-grid/product-ratings.svg',
          link: localizedRoute('/ordering')
        },
        {
          label: 'Promotional newspapers',
          class: 'm-menu-grid__item--promotional-newspapers',
          image: '/assets/images/menu/menu-grid/promotional-newspapers.svg',
          link: localizedRoute('/gazeta-varus-0')
        },
        {
          label: 'Become part of VARUS',
          class: 'm-menu-grid__item--articles-and-recipes',
          image: '/assets/images/menu/menu-grid/articles-and-recipes.svg',
          link: localizedRoute('/smachna_robota')
        }
      ]
    })

    const close = () => vm.$emit('close')

    return {
      menuGrid,
      close
    }
  }
});
